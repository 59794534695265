<template>
  <div class="base-steps horizontal" :class="{ 'cup': isShowCup }" v-if="direction == 'horizontal'">
    <div class="progress" :style="{ width:(active-1) * 100 / (steps-1) + '%'}"></div>
    <div class="point" :class="[{ 'on': active == step },{ 'finish': active > step }]" v-for="step in steps" :key="step" :style="{ left: 100 / (steps-1) * (step-1) +  '%' }"></div>
  </div>
  <div class="base-steps vertical" :class="{ 'cup': isShowCup }" v-else  :style="{ height:(steps-1) * height + (4*steps) + 'px'}">
    <div class="progress" :style="{ height:(active-1) * height + 'px'}"></div>
    <div class="point" :class="[{ 'on': step == active },{ 'finish': active > step }]" v-for="step in steps" :key="step" :style="{ top: height * (step-1) + (4*step) + 'px' }"></div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
import { log } from "@/common/utils";
export default {
  name: "baseSteps",
  props: {
    direction: {
      type: String,
      default: 'horizontal'
    },
    steps: {
      type: Number,
      default: 4
    },
    active: {
      type: Number,
      default: 1
    },
    height: {
      type: Number,
      default: 100
    },
    isShowCup: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      
    };
  },
  methods: {
    
  }
};
</script>
<style lang="scss">
@import "@/assets/scss/theme.scss";
.base-steps { position: relative;
  .point { width:22px; height:22px; border-radius: 50%; border:$c-white 3px solid; background: #EAEFF3; z-index: 3; }
  &:before,.progress { @include a-b;  }
  &:before { background: #EAEFF3; }
  &.vertical { width:20px; height:100%;
    &:before,.progress { @include h-c; width:10px; }
    &:before { height:100%; }
    .progress { background:url(../../assets/images/steps_v_bg.jpg) repeat-y center; }
    .point { @include h-c; transform: translate(-50%, -50%);
      &:nth-of-type(2) { top:-3px !important; transform: translate(-50%, 0); }
      &:last-child { top:auto !important; bottom:-3px; transform: translate(-50%, 0); }
      &.finish { background:$c-success; }
      &.on { background:$c-primary; }
    }
  }
  &.horizontal { width:100%; height:20px;
    &:before,.progress { @include v-c; height:10px; }
    &:before { width:100%; }
    .progress { background:url(../../assets/images/steps_h_bg.jpg) repeat-x center; }
    .point { @include v-c; transform: translate(-50%, -50%);
      &:nth-of-type(2) { left:-3px !important; transform: translate(0, -50%); }
      &:last-child { left:auto !important; right:-3px; transform: translate(0, -50%); }
      &.finish { background:$c-success; }
      &.on { background:$c-primary; }
    }
  }

}
</style>